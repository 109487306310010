import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const SectionTitle = ({ title, textColor }) => (
  <div data-sal="slide-down" data-sal-duration="900">
    <Title textColor={textColor}>{title}</Title>
    <Divider bgColor={textColor} />
  </div>
)

const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-top: 0;
  color: ${props=> props.textColor}
`
const Divider = styled.hr`
  width: 80px;
  height: 5px;
  background-color: ${props=> props.bgColor};
  border-width: 0;
  border-radius: 15px;
`

export default SectionTitle

SectionTitle.propTypes = {
  title: PropTypes.string,
  textColor: PropTypes.string,
}
