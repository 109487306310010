import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import sanitizeHtml from "sanitize-html"

const FeatureCard = ({
  iconSrc,
  cardColor,
  title,
  marginRight,
  isParentFeature,
  cardInfo,
  cardInfoTextColor,
}) => {
  return (
    <CardContainer
      cardColor={cardColor}
      marginRight={marginRight}
      isParentFeature={isParentFeature}
    >
      <TitleContainer isParentFeature={isParentFeature}>
        <Icon
          src={iconSrc}
          alt="Icono disciplina"
          isParentFeature={isParentFeature}
        />
        <CardTitle isParentFeature={isParentFeature}>{title}</CardTitle>
      </TitleContainer>
      {isParentFeature && (
        <CardInfo
          isParentFeature={isParentFeature}
          cardInfoTextColor={cardInfoTextColor}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(cardInfo, {
              allowedTags: ["strong"],
              allowedAttributes: {
                strong: ["style"],
              },
              allowedStyles: {
                "*": {
                  // Match HEX and RGB
                  color: [
                    /^#(0x)?[0-9a-f]+$/i,
                    /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                  ],
                },
              },
            }),
          }}
        />
      )}
    </CardContainer>
  )
}
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  min-width: 200px;
  max-width: ${props => (props.isParentFeature ? "800px" : "100px")};
  max-height: 250px;
  padding: ${props => (props.isParentFeature ? "25px" : "5px")};
  background-color: ${props => props.cardColor};
  margin-right: ${props => props.marginRight};
  @media screen and (max-width: 670px) {
    min-width: 100%;
    margin-bottom: 20px;
    max-height: 100%;
  }
  
`

const TitleContainer = styled.div`
  display: ${props => (props.isParentFeature ? "flex" : "block")};
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
`

const CardTitle = styled.h3`
  margin-top: ${props => (props.isParentFeature ? "0" : "5px")};
  margin-bottom: ${props => (props.isParentFeature ? "0" : "5px")};
  color: #f5f4f4;
  font-size: ${props => (props.isParentFeature ? "1.2rem" : "1rem")};
  text-align: center;
`
const CardInfo = styled.p`
  color: ${props => props.cardInfoTextColor};
  text-align: ${props => (props.isParentFeature ? "center" : "inherit")};
  line-height: ${props => (props.isParentFeature ? "2rem" : "1rem")};
  font-size: 1rem;
`

const Icon = styled.img`
  margin-top: ${props => (props.isParentFeature ? "0" : "10px")};
  width: ${props => (props.isParentFeature ? "50px" : "70px")};
  margin-right: ${props => (props.isParentFeature ? "10px" : "0")};
`

FeatureCard.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  cardColor: PropTypes.string.isRequired,
  title: PropTypes.string,
  cardInfo: PropTypes.string,
  marginRight: PropTypes.string,
}

export default FeatureCard
