import * as React from "react"
import styled from "styled-components"
import sanitizeHtml from "sanitize-html"

import text from "../constants/labels.json"
import warningIcon from "../images/warning.svg"
import checklistIcon from "../images/checklist.svg"
import idIcon from "../images/id.svg"
import valueIcon from "../images/value.svg"
import evalIcon from "../images/eval.svg"
import SectionTitle from "./sectionTitle"
import FeatureCard from "./featureCard"
import Wrapper from "./wrapper"

const Parents = () => {
  return (
    <>
      <Wrapper id="padres">
        <SectionTitle title={text.labels.pages.parents} textColor="#26272d" />
        <ParentsContainer>
          <InfoSection>
            <Info data-sal="fade" data-sal-duration="600">
              <ParentsTitle
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(text.labels.info.parents.title, {
                    allowedTags: ["strong"],
                    allowedAttributes: {
                      strong: ["style"],
                    },
                    allowedStyles: {
                      "*": {
                        // Match HEX and RGB
                        color: [
                          /^#(0x)?[0-9a-f]+$/i,
                          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                        ],
                      },
                    },
                  }),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(text.labels.info.parents.firstText, {
                    allowedTags: ["strong"],
                    allowedAttributes: {
                      strong: ["style"],
                    },
                    allowedStyles: {
                      "*": {
                        // Match HEX and RGB
                        color: [
                          /^#(0x)?[0-9a-f]+$/i,
                          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                        ],
                      },
                    },
                  }),
                }}
              />
            </Info>
          </InfoSection>
          <FeaturesContainer>
            <BeforeFeaturesContainer data-sal="slide-right" data-sal-duration="600">
              <h3>
                <strong>
                  <u>{text.labels.info.parents.features.before.title}</u>
                </strong>
              </h3>
              <FeaturesCardsContainer
                justifyContent="space-around"
                marginBottom="20px"
              >
                <FeatureCard
                  isParentFeature={true}
                  iconSrc={warningIcon}
                  cardColor="#5a71eb"
                  title={
                    text.labels.info.parents.features.before.evaluate.title
                  }
                  cardInfo={
                    text.labels.info.parents.features.before.evaluate.info
                  }
                  cardInfoTextColor="#fffc"
                />
                <FeatureCard
                  isParentFeature={true}
                  iconSrc={checklistIcon}
                  cardColor="#5a71eb"
                  title={
                    text.labels.info.parents.features.before.protocol.title
                  }
                  cardInfo={
                    text.labels.info.parents.features.before.protocol.info
                  }
                  cardInfoTextColor="#fffc"
                />
              </FeaturesCardsContainer>
            </BeforeFeaturesContainer>
            <DuringFeaturesContainer data-sal="slide-left" data-sal-duration="600">
              <h3>
                <strong>
                  <u>{text.labels.info.parents.features.during.title}</u>
                </strong>
              </h3>
              <FeaturesCardsContainer
                justifyContent="space-around"
                marginBottom="20px"
              >
                <FeatureCard
                  isParentFeature={true}
                  iconSrc={idIcon}
                  cardColor="#6fa73b"
                  title={text.labels.info.parents.features.during.systems.title}
                  cardInfo={
                    text.labels.info.parents.features.during.systems.info
                  }
                  cardInfoTextColor="#fffc"
                />
                <FeatureCard
                  isParentFeature={true}
                  iconSrc={valueIcon}
                  cardColor="#6fa73b"
                  title={text.labels.info.parents.features.during.values.title}
                  cardInfo={
                    text.labels.info.parents.features.during.values.info
                  }
                  cardInfoTextColor="#fffc"
                />
              </FeaturesCardsContainer>
            </DuringFeaturesContainer>
            <h3 data-sal="fade" data-sal-duration="600">
              <strong>
                <u>{text.labels.info.parents.features.after.title}</u>
              </strong>
            </h3>
            <FeaturesCardsContainer data-sal="slide-up" data-sal-duration="600"
              justifyContent="space-around"
              marginBottom="20px"
            >
              <FeatureCard
                isParentFeature={true}
                iconSrc={evalIcon}
                cardColor="#d964a6"
                title={text.labels.info.parents.features.after.evaluate.title}
                cardInfo={text.labels.info.parents.features.after.evaluate.info}
                cardInfoTextColor="#fffc"
              />
            </FeaturesCardsContainer>
          </FeaturesContainer>
        </ParentsContainer>
      </Wrapper>
    </>
  )
}

const InfoSection = styled.div`
  display: flex;
  position: relative;
  font-size: 1.15rem;
  line-height: 2rem;
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`

const ParentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 80px;
`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const BeforeFeaturesContainer = styled.div`
  position: relative;
  text-align: center;
`
const DuringFeaturesContainer = styled.div`
  position: relative;
  text-align: center;
`

const FeaturesCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  margin-bottom: ${props => props.marginBottom};
  > * {
    margin-bottom: 30px;
  }
`

const ParentsTitle = styled.h3`
  margin-top: 0;
`

export default Parents
