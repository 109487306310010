import * as React from "react"
import styled from "styled-components"
import sanitizeHtml from "sanitize-html"

import SectionTitle from "./sectionTitle"
import TripsCard from "./tripsCard"
import text from "../constants/labels.json"
import educaLenguajePic from "../images/educaLenguaje.jpeg"
import educaArtePic from "../images/educaArte.jpeg"
import educaCienciaPic from "../images/educaCiencia.jpeg"
import educaHistoriaPic from "../images/educaHistoria.jpeg"
import wave from "../images/wave2.svg"
import bottomWave from "../images/wave4.svg"
import Wrapper from "./wrapper"

const Trips = () => {
  return (
    <>
      <Wave src={wave} />
      <Background id="salidas">
        <Wrapper>
          <SectionTitle title={text.labels.pages.trips} textColor="#26272d" />
          <TripsContainer>
            <InfoSection>
              <TripsTitle data-sal="fade" data-sal-duration="600">
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(text.labels.info.visits.title, {
                      allowedTags: ["strong"],
                      allowedAttributes: {
                        strong: ["style"],
                      },
                      allowedStyles: {
                        "*": {
                          // Match HEX and RGB
                          color: [
                            /^#(0x)?[0-9a-f]+$/i,
                            /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                          ],
                        },
                      },
                    }),
                  }}
                />
              </TripsTitle>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(text.labels.info.visits.lead, {
                    allowedTags: ["strong"],
                    allowedAttributes: {
                      strong: ["style"],
                    },
                    allowedStyles: {
                      "*": {
                        // Match HEX and RGB
                        color: [
                          /^#(0x)?[0-9a-f]+$/i,
                          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                        ],
                      },
                    },
                  }),
                }}
              />
              <TripsCardContainer data-sal="slide-right" data-sal-duration="600">
                <TripsCard
                  picSrc={educaLenguajePic}
                  cardColor="#5a71eb"
                  title="Lenguaje"
                  buttonTextColor="#f5f4f4"
                  buttonBgColor="#d964a6"
                  buttonHoverColor="#eb7cbb"
                  buttonActiveColor="#cf5d9d"
                  isRight={true}
                  link="lenguaje"
                />
              </TripsCardContainer>
              <TripsCardContainer data-sal="slide-left" data-sal-duration="600">
                <TripsCard
                  picSrc={educaArtePic}
                  cardColor="#d964a6"
                  title="Artes"
                  buttonTextColor="#f5f4f4"
                  buttonBgColor="#5a71eb"
                  buttonHoverColor="#677ef5"
                  buttonActiveColor="#4b61d6"
                  isRight={false}
                  link="artes"
                />
              </TripsCardContainer>
              <TripsCardContainer data-sal="slide-right" data-sal-duration="600">
                <TripsCard
                  picSrc={educaCienciaPic}
                  cardColor="#6fa73b"
                  title="Ciencias"
                  buttonTextColor="#f5f4f4"
                  buttonBgColor="#e7b84c"
                  buttonHoverColor="#f2c45a"
                  buttonActiveColor="#c79b38"
                  isRight={true}
                  link="ciencias"
                />
              </TripsCardContainer>
              <TripsCardContainer data-sal="slide-left" data-sal-duration="600">
                <TripsCard
                  picSrc={educaHistoriaPic}
                  cardColor="#e7b84c"
                  title="Historia"
                  buttonTextColor="#f5f4f4"
                  buttonBgColor="#6fa73b"
                  buttonHoverColor="#7eb54a"
                  buttonActiveColor="#588a2b"
                  isRight={false}
                  link="historia"
                />
              </TripsCardContainer>
            </InfoSection>
          </TripsContainer>
        </Wrapper>
      </Background>
      <BottomWave src={bottomWave} />
    </>
  )
}

const Wave = styled.img`
  width: 100%;
  margin: 0;
  margin-bottom: -10px;
  position: relative;
  z-index: -1;
`
const BottomWave = styled.img`
  width: 100%;
  margin: 0;
  position: relative;
  z-index: -1;
`

const Background = styled.div`
  background-color: #c5e5a7;
  width: 100%;
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 650px;
  font-size: 1.15rem;
  line-height: 2rem;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`
const TripsCardContainer = styled.div`
  width: 100%;
`

const TripsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`

const TripsTitle = styled.h3`
  margin-top: 0;
  text-align: center;
`

export default Trips
