import * as React from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import styled from "styled-components"
import SectionTitle from "./sectionTitle"
import text from "../constants/labels.json"
import wave from "../images/wave2.svg"
import Wrapper from "./wrapper"

const Contact = () => {
  return (
    <>
      <Wave src={wave} />
      <Background id="contacto">
        <Wrapper>
          <SectionTitle title={text.labels.pages.contact} textColor="#26272d" />
          <ContactContainer>
            <Formik
              initialValues={{
                name: "",
                email: "",
                phone: "",
                message: "",
              }}
              onSubmit={(values, actions) => {
                alert(JSON.stringify(values, null, 2))
                actions.setSubmitting(false)
              }}
              validate={values => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                const phoneRegex = /^\d{9}$/
                const errors = {}
                if (!values.name) {
                  errors.name = "Ingresa un nombre"
                }
                if (!values.email || !emailRegex.test(values.email)) {
                  errors.email = "E-mail válido requerido"
                }
                if (!phoneRegex.test(values.phone)) {
                  errors.phone =
                    "Número no válido, por favor ingresa un número de 9 dígitos"
                }
                if (!values.message) {
                  errors.message = "Ingresa tu mensaje"
                }
                return errors
              }}
            >
              {() => (
                <StyledForm data-sal="slide-right" data-sal-duration="600">
                  <InputContainer>
                    <StyledLabel htmlFor="name">Nombre: </StyledLabel>
                    <StyledField name="name" />
                  </InputContainer>
                  <ErrorMessage name="name">
                    {msg => <StyledErrorMessage>{msg}</StyledErrorMessage>}
                  </ErrorMessage>
                  <InputContainer>
                    <StyledLabel htmlFor="email">E-mail: </StyledLabel>
                    <StyledField name="email" />
                  </InputContainer>
                  <ErrorMessage name="email">
                    {msg => <StyledErrorMessage>{msg}</StyledErrorMessage>}
                  </ErrorMessage>
                  <InputContainer>
                    <StyledLabel htmlFor="phone">Teléfono: </StyledLabel>
                    <StyledField name="phone" />
                  </InputContainer>
                  <ErrorMessage name="phone">
                    {msg => <StyledErrorMessage>{msg}</StyledErrorMessage>}
                  </ErrorMessage>
                  <InputContainer>
                    <StyledLabel htmlFor="message">Mensaje: </StyledLabel>
                    <StyledField name="message" component="textarea" />
                  </InputContainer>
                  <ErrorMessage name="message">
                    {msg => <StyledErrorMessage>{msg}</StyledErrorMessage>}
                  </ErrorMessage>
                  <StyledButton
                    buttonBgColor="#5a71eb"
                    buttonTextColor="#fffc"
                    buttonHoverColor="#677ef5"
                    buttonActiveColor="#4b61d6"
                    type="submit"
                  >
                    Enviar
                  </StyledButton>
                </StyledForm>
              )}
            </Formik>
            <ContactInfo data-sal="slide-left" data-sal-duration="600">
              <ContactSection>
                <h3>Teléfonos:</h3>
                <PhoneLinks>
                  <a href="tel:+56981891716">+56981891716</a>
                  <a href="tel:+56956079756">+56956079756</a>
                </PhoneLinks>
              </ContactSection>
              <ContactSection>
                <h3>E-mail:</h3>
                <a href="mailto:educaturegion@gmail.com">
                  educaturegion@gmail.com
                </a>
              </ContactSection>
              <h3>Ubicación:</h3>
              <iframe style={{border: 0}} title="map" marginHeight="0" marginWidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3654.661507418085!2d-70.40125338491484!3d-23.652290370841836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96afd5112f6da23d%3A0x2a4b9cf02ba5b25a!2sManuel%20Antonio%20Matta%202216%2C%20Antofagasta!5e0!3m2!1ses-419!2scl!4v1609904951228!5m2!1ses-419!2scl" frameBorder="0"></iframe>
            </ContactInfo>
          </ContactContainer>
        </Wrapper>
      </Background>
    </>
  )
}

const Wave = styled.img`
  width: 100%;
  margin: 0;
  margin-bottom: -10px;
  position: relative;
  z-index: -1;
`

const Background = styled.div`
  background-color: #c5e5a7;
  width: 100%;
`
const StyledForm = styled(Form)`
  display: flex;
  color: #f5f4f4;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #6fa73b;
  padding: 20px;
  border-radius: 8px;
  @media screen and (max-width: 900px) {
    margin-bottom: 30px;
  }
`
const StyledLabel = styled.label`
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 20px;
`

const StyledErrorMessage = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  color: #e7b84c;
  margin-top: 0;
`

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  margin-bottom: 20px;
`
const ContactInfo = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #f5f4f4;
  padding: 20px;
  border-radius: 8px;

  iframe {
    width: 100%;
    height: 350px;
  }
`
const PhoneLinks = styled.div`
  display: flex;
  flex-direction: column;
`
const ContactSection = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 20px;
  }
`

const StyledButton = styled.button`
  margin-top: 10px;
  position: relative;
  transition: all 250ms;
  text-align: center;
  color: ${props => props.buttonTextColor};
  background-color: ${props => props.buttonBgColor};
  width: 200px;
  padding: 20px;
  font-weight: bold;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  cursor: pointer;
  &:hover,
  focus {
    background-color: ${props => props.buttonHoverColor};
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    background-color: ${props => props.buttonActiveColor};
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }
`
const StyledField = styled(Field)`
  width: 300px;
  padding: 10px;
  font-weight: 400;
  font-family: "Sniglet", "cursive";
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  @media screen and (max-width: 475px) {
    width: 100%;
  }
`
const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  padding-bottom: 150px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`

export default Contact
