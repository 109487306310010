import * as React from "react"
import styled, { css } from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import text from "../constants/labels.json"
import Wrapper from "./wrapper"

const Hero = () => {
  const buttonTheme = {
    primary: {
      firstBgColor: "#294290",
      secondBgColor: "#6fa73b",
      textColor: "#fff",
      marginRight: "30px",
      hoverColor: "#294290",
    },
    secondary: {
      firstBgColor: "#fff",
      secondBgColor: "#fff",
      textColor: "black",
      marginRight: "0",
      hoverColor: "#6fa73b",
    },
  }

  return (
    <Wrapper>
      <HeroContainer>
        <CtaContainer
          data-sal="slide-right"
          data-sal-duration="600"
          data-sal-delay="200"
          data-sal-easing="ease"
        >
          <Title>{text.labels.heroText.ctaTitle}</Title>
          <Subtitle>{text.labels.heroText.ctaSubtitlePrimary}</Subtitle>
          <StyledSubtitle>
            {text.labels.heroText.ctaSubtitleSecondary}
          </StyledSubtitle>
          <ButtonContainer>
            <CtaButton buttonTheme={buttonTheme.primary} href="https://wa.me/56956079756/" target="_blank">
              {text.labels.buttons.ctaPrimary}
            </CtaButton>
            <CtaButton buttonTheme={buttonTheme.secondary} href="#servicios">
              {text.labels.buttons.ctaSecondary}
            </CtaButton>
          </ButtonContainer>
        </CtaContainer>
        <StaticImage
          className="hero"
          src="../images/teatroHero.png"
          width={887}
          quality={100}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="teatro salitrera chacabuco"
          style={{ marginBottom: `1.45rem` }}
        />
      </HeroContainer>
    </Wrapper>
  )
}

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @media screen and (max-width: 1024px) {
    align-items: center;
  }
`

const HeroContainer = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 200px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  }
`

const Title = styled.h1`
  font-size: 3.2rem;
  font-weight: bold;
  max-width: 700px;
  @media screen and (max-width: 475px) {
    padding: 35px;
  }
`

const Subtitle = styled.h3`
  font-size: 1.4rem;
  font-weight: 400;
`
const StyledSubtitle = styled(Subtitle)`
  margin-top: 0;
`

const CtaButton = styled.a(
  ({ buttonTheme }) => css`
    background-image: linear-gradient(
      90deg,
      ${buttonTheme.firstBgColor},
      ${buttonTheme.secondBgColor}
    );
    margin-right: ${buttonTheme.marginRight};
    margin-top: 10px;
    color: ${buttonTheme.textColor};
    width: 200px;
    padding: 20px;
    font-weight: bold;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 8px;
    border: none;
    transition: background 0.5s ease;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background: ${buttonTheme.hoverColor};
    }
    &:active {
      box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
        rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
        rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    }

    @media screen and (max-width: 475px) {
      margin-right: 0;
    }
  `
)

const ButtonContainer = styled.div`
  display: flex;
  @media screen and (max-width: 475px) {
    flex-direction: column;
    align-items: center;
  }
`

export default Hero
