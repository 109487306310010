import * as React from "react"
import styled from "styled-components"
import sanitizeHtml from "sanitize-html"
import { StaticImage } from "gatsby-plugin-image"

import text from "../constants/labels.json"
import wave from "../images/wave.svg"
import sobrePic from "../images/educaTuRegionAbout.png"
import bottomWave from "../images/wave3.svg"
import SectionTitle from "./sectionTitle"
import Wrapper from "./wrapper"

const About = () => {
  return (
    <>
      <Wave src={wave} />
      <Background id="sobre">
        <Wrapper>
          <SectionTitle title={text.labels.pages.about} textColor="#f5f4f4" />
          <AboutContainer>
            <InfoSection data-sal="slide-right" data-sal-duration="600">
              <AboutTitle>{text.labels.info.about.title}</AboutTitle>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(text.labels.info.about.firstText, {
                    allowedTags: ["strong"],
                    allowedAttributes: {
                      strong: ["style"],
                    },
                    allowedStyles: {
                      "*": {
                        // Match HEX and RGB
                        color: [
                          /^#(0x)?[0-9a-f]+$/i,
                          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                        ],
                      },
                    },
                  }),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(text.labels.info.about.secondText, {
                    allowedTags: ["strong"],
                    allowedAttributes: {
                      strong: ["style"],
                    },
                    allowedStyles: {
                      "*": {
                        // Match HEX and RGB
                        color: [
                          /^#(0x)?[0-9a-f]+$/i,
                          /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                        ],
                      },
                    },
                  }),
                }}
              />
            </InfoSection>
          </AboutContainer>
        </Wrapper>
      </Background>
      <BottomWave src={bottomWave} />
    </>
  )
}

const Wave = styled.img`
  width: 100%;
  margin: 0;
  margin-bottom: -10px;
  position: relative;
  z-index: -1;
`
const BottomWave = styled.img`
  width: 100%;
  margin: 0;
  position: relative;
  z-index: -1;
`

const Background = styled.div`
  background-color: #5a71eb;
  width: 100%;
`

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 650px;
  font-size: 1.15rem;
  line-height: 2rem;
  z-index: 1;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const AboutContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  color: #f5f4f4;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    &:before {
      content: "";
      position: absolute;
      background-image: url(${sobrePic});
      background-position: right;
      background-size: contain;
      background-repeat: no-repeat;
      top: 0;
      bottom: 0;
      left: 10%;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
  
`

const AboutTitle = styled.h3`
  margin-top: 0;
`

export default About
