import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import Services from "../components/services"
import HeroCards from "../components/heroCards"
import Trips from "../components/trips"
import Parents from "../components/parents"
import Contact from "../components/contact"

const IndexPage = () => (
  <Layout>
    <Seo title="Inicio" />
    <Hero />
    <HeroCards />
    <About />
    <Services />
    <Trips />
    <Parents />
    <Contact />
    <script src="https://apps.elfsight.com/p/platform.js" defer></script>
    <div className="elfsight-app-ba137812-229a-42f3-8c1c-1652be152941"></div>
  </Layout>
)

export default IndexPage
