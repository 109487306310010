import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import SpeechBubble from "./speechBubble"

const Card = ({ iconSrc, cardColor, title, visits }) => {
  const [inHover, setHover] = useState(false)

  return (
    <>
      <CardContainer
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        cardColor={cardColor}
      >
        {inHover && <SpeechBubble isShowing visits={visits} bgColor={`${cardColor}70`} />}
        <Icon src={iconSrc} alt="Icono disciplina" />
        <CardTitle>{title}</CardTitle>
      </CardContainer>
    </>
  )
}
const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  min-width: 200px;
  max-height: 150px;
  background-color: ${props => props.cardColor};
`

const CardTitle = styled.h3`
  font-size: 1rem;
  margin-top: 5px;
  color: #f5f4f4;
`
const Icon = styled.img`
  width: 70px;
  margin-top: 10px;
`
Card.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  cardColor: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Card
