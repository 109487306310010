import * as React from "react"
import styled from "styled-components"

import Card from "../components/card"
import scienceIcon from "../images/science.svg"
import historyIcon from "../images/history.svg"
import languageIcon from "../images/book.svg"
import artsIcon from "../images/art-3.svg"
import text from "../constants/labels.json"
import Wrapper from "./wrapper"

const HeroCards = () => (
  <Wrapper>
    <CardsContainer data-sal="slide-down" data-sal-duration="600">
      <Card
        iconSrc={scienceIcon}
        title="Ciencias"
        visits={text.labels.info.visits.science}
        cardColor="#6fa73b"
      />
      <Card
        iconSrc={historyIcon}
        title="Historia"
        visits={text.labels.info.visits.history}
        cardColor="#e7b84c"
      />
      <Card
        iconSrc={languageIcon}
        title="Lenguaje"
        visits={text.labels.info.visits.language}
        cardColor="#294290"
      />
      <Card
        iconSrc={artsIcon}
        title="Artes"
        visits={text.labels.info.visits.arts}
        cardColor="#b70f6d"
      />
    </CardsContainer>
  </Wrapper>
)

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 150px;
  margin-bottom: -230px;
  @media screen and (max-width: 1150px) {
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
  }
`

export default HeroCards
