import * as React from "react"
import styled from "styled-components"
import sanitizeHtml from "sanitize-html"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import text from "../constants/labels.json"
import busIcon from "../images/bus.svg"
import driverIcon from "../images/driver.svg"
import guideIcon from "../images/guidebook.svg"
import tourGuideIcon from "../images/tour-guide.svg"
import serviciosPic from "../images/educaTuRegionServicios.png"
import photoIcon from "../images/photo.svg"
import SectionTitle from "./sectionTitle"
import FeatureCard from "./featureCard"
import Wrapper from "./wrapper"

const Services = () => {
  return (
    <>
      <Wrapper id="servicios">
        <SectionTitle
          title={text.labels.pages.ourServices}
          textColor="#26272d"
        />
        <ServicesContainer>
          {/* <StaticImage
            src="https://via.placeholder.com/550"
            width={400}
            imgStyle={{ maxHeight: "400px" }}
            quality={100}
            alt="Place kitten"
          /> */}
          <InfoSection>
            <ServicesTitle data-sal="slide-right" data-sal-duration="600">
              {text.labels.info.ourServices.title}
            </ServicesTitle>
            <p
              data-sal="slide-left"
              data-sal-duration="600"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(text.labels.info.ourServices.firstText, {
                  allowedTags: ["strong"],
                  allowedAttributes: {
                    strong: ["style"],
                  },
                  allowedStyles: {
                    "*": {
                      // Match HEX and RGB
                      color: [
                        /^#(0x)?[0-9a-f]+$/i,
                        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                      ],
                    },
                  },
                }),
              }}
            />
            <p
              data-sal="slide-left"
              data-sal-duration="600"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(text.labels.info.ourServices.secondText, {
                  allowedTags: ["strong"],
                  allowedAttributes: {
                    strong: ["style"],
                  },
                  allowedStyles: {
                    "*": {
                      // Match HEX and RGB
                      color: [
                        /^#(0x)?[0-9a-f]+$/i,
                        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
                      ],
                    },
                  },
                }),
              }}
            />
            <FeaturesContainer data-sal="slide-up" data-sal-duration="600">
              <p>
                <strong>
                  <u>{text.labels.info.ourServices.features.lead}</u>
                </strong>
              </p>
              <FeaturesCardsContainer
                justifyContent="space-between"
                marginBottom="20px"
              >
                <FeatureCard
                  iconSrc={busIcon}
                  cardColor="#5a71eb"
                  title={text.labels.info.ourServices.features.bus}
                />
                <FeatureCard
                  iconSrc={driverIcon}
                  cardColor="#5a71eb"
                  title={text.labels.info.ourServices.features.driver}
                />
                <FeatureCard
                  iconSrc={guideIcon}
                  cardColor="#5a71eb"
                  title={text.labels.info.ourServices.features.guide}
                />
              </FeaturesCardsContainer>
              <FeaturesCardsContainer justifyContent="center">
                <FeatureCard
                  iconSrc={tourGuideIcon}
                  cardColor="#98c66d"
                  title={text.labels.info.ourServices.features.monitor}
                  marginRight="10px"
                />
                <FeatureCard
                  iconSrc={photoIcon}
                  cardColor="#98c66d"
                  title={text.labels.info.ourServices.features.photos}
                />
              </FeaturesCardsContainer>
            </FeaturesContainer>
          </InfoSection>
        </ServicesContainer>
      </Wrapper>
    </>
  )
}

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  margin-left: 20px;
  font-size: 1.15rem;
  line-height: 2rem;
  z-index: 1;
  @media screen and (max-width: 1024px) {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
`
const ServicesContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: end;
  margin-top: 80px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1024px) {
    &:before {
      content: "";
      position: absolute;
      background-image: url(${serviciosPic});
      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      top: -30%;
      bottom: 0;
      right: 45%;
      width: 65%;
      height: 100%;
      z-index: 0;
    }
  }
  
`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const FeaturesCardsContainer = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
  margin-bottom: ${props => props.marginBottom};
  @media screen and (max-width: 670px) {
    flex-direction: column;
    width:100;
  }
`

const ServicesTitle = styled.h3`
  margin-top: 0;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`

export default Services
