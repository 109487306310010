import React from "react"
import styled from "styled-components"

const SpeechBubble = ({ isShowing, visits, bgColor }) => (
  <Container>
    {isShowing && (
      <SpeechBubbleContainer bgColor={bgColor}>
        <Info>
          {visits.map(visit => (
            <Title>{visit}</Title>
          ))}
        </Info>
      </SpeechBubbleContainer>
    )}
  </Container>
)

const Container = styled.div`
  position: absolute;
  top: -200px;
  z-index: 20;
`

const Title = styled.h4`
  text-align: center;`
const Info = styled.div`
  padding: 10px;`
const SpeechBubbleContainer = styled.div`
  position: relative;
  width: 300px;
  height: 200px;
  background-color: ${props => props.bgColor};
  border-radius: 0.4em;
  -webkit-box-shadow: 0px 3px 8px 4px rgba(0, 0, 0, 0.54);
  box-shadow: 0px 3px 8px 4px rgba(0, 0, 0, 0.54);
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: white;
    border-bottom: 0;
    margin-left: -20px;
    margin-bottom: -20px;
  }
`
export default SpeechBubble
