import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

const TripsCard = ({
  picSrc,
  cardColor,
  title,
  buttonBgColor,
  buttonTextColor,
  buttonHoverColor,
  buttonActiveColor,
  link,
  isRight,
}) => {
  return (
    <>
      <CardContainer cardColor={cardColor}>
        {isRight && <Pic src={picSrc} alt="Icono disciplina" />}
        <InfoContainer>
          <CardTitle>{title}</CardTitle>
          <StyledLink
            to={link}
            buttonTextColor={buttonTextColor}
            buttonBgColor={buttonBgColor}
            buttonHoverColor={buttonHoverColor}
            buttonActiveColor={buttonActiveColor}
          >
            Más info
          </StyledLink>
        </InfoContainer>
        {!isRight && <Pic src={picSrc} alt="Icono disciplina" />}
      </CardContainer>
    </>
  )
}
const CardContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  border-radius: 6px;
  min-width: 600px;
  max-height: 250px;
  background-color: ${props => props.cardColor};
  @media screen and (max-width: 768px) {
    min-width: 100%;
  }
  @media screen and (max-width: 500px) {
    min-width: 100%;
    flex-direction: column;
    max-height: 100%;
  }
`

const CardTitle = styled.h1`
  margin-top: 5px;
  color: #f5f4f4;
`
const Pic = styled.img`
  width: 200px;
  margin-top: 10px;
  border: 4px solid #f5f4f4;
  border-radius: 20px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLink = styled(Link)`
  position: relative;
  transition: all 250ms;
  margin-top: 10px;
  text-align: center;
  color: ${props => props.buttonTextColor};
  background-color: ${props => props.buttonBgColor};
  width: 200px;
  padding: 20px;
  font-weight: bold;
  font-size: 1.1rem;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;

  &:hover,
  focus {
    background-color: ${props => props.buttonHoverColor};
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  }

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    background-color: ${props => props.buttonActiveColor};
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }
`

TripsCard.propTypes = {
  picSrc: PropTypes.string.isRequired,
  cardColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  buttonBgColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonActiveColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,

}

export default TripsCard
